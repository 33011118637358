import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';

import { IStepsTimelinePoint } from '../../models/steps-timeline-point.model';
import { ITitleProps } from '../atoms/title';
import { ITextProps } from '../atoms/text';
import { TButtonProps } from '../atoms/button';

import Section from '../hoc/section';
import StepsTimeline from '../molecules/steps-timeline';

interface ISectionHowWeWorkProps {
    className?: string;
    titleProps?: ITitleProps;
    textProps?: ITextProps;
    buttonProps?: TButtonProps;
    points: IStepsTimelinePoint[];
}

interface ISectionHowWeWorkQueryResult {
    image: ImageDataLike;
}

const SectionHowWeWork: React.FC<ISectionHowWeWorkProps> = ({
    className = '',
    titleProps,
    textProps,
    buttonProps,
    points,
}) => {
    const { image }: ISectionHowWeWorkQueryResult = useStaticQuery(query);

    return (
        <Section
            className={className}
            theme="dark"
            circleColor="purple"
            circleDisplay="left"
            contentDisplay="right"
            titleProps={titleProps}
            textProps={textProps}
            image={image && getImage(image)}
            buttonProps={{ ...buttonProps }}
        >
            <StepsTimeline points={points} TitleTag="h3" />
        </Section>
    );
};

const query = graphql`
    query {
        image: file(relativePath: { eq: "section-how.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
`;

export default SectionHowWeWork;
