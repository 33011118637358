// extracted by mini-css-extract-plugin
export var blue = "about-card-module--blue--TyciF";
export var button = "about-card-module--button--tJgVe";
export var circle = "about-card-module--circle--j1Hy0";
export var container = "about-card-module--container--4S2OL";
export var header = "about-card-module--header--ORTnV";
export var pink = "about-card-module--pink--H2d2d";
export var purple = "about-card-module--purple--WnBEI";
export var svg = "about-card-module--svg--0vzFr";
export var textBox = "about-card-module--text-box--AHt4O";
export var titleText = "about-card-module--title-text--etdGG";
export var yellow = "about-card-module--yellow--7HZ5x";