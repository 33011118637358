import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';

import { contentBox, item, card } from './section-portfolio.module.scss';
import { IProjectCard } from '../../models/project';
import { ITitleProps } from '../atoms/title';
import { TButtonProps } from '../atoms/button';
import { ITextProps } from '../atoms/text';

import Section from '../hoc/section';
import ProjectCard from '../molecules/project-card';

interface ISectionPortfolioProps {
    className?: string;
    titleProps?: ITitleProps;
    textProps?: ITextProps;
    buttonProps?: TButtonProps;
    projectCards: IProjectCard[];
}

interface ISectionPortfolioQueryResult {
    image: ImageDataLike;
}

const SectionPortfolio: React.FC<ISectionPortfolioProps> = ({
    className = '',
    projectCards,
    buttonProps,
    titleProps,
    textProps,
}) => {
    const { image }: ISectionPortfolioQueryResult = useStaticQuery(query);

    return (
        <Section
            className={className}
            contentBoxClassName={contentBox}
            theme="light"
            circleColor="yellow"
            circleDisplay="right"
            contentDisplay="left"
            titleProps={titleProps}
            textProps={textProps}
            image={image && getImage(image)}
            buttonProps={{ ...buttonProps }}
        >
            {projectCards.length > 0 && (
                <ul>
                    {projectCards.map((projectCard) => {
                        return (
                            <li key={`project-item-${projectCard.projectId}`} className={item}>
                                <ProjectCard
                                    className={card}
                                    projectCard={projectCard}
                                    ClientTag="h3"
                                />
                            </li>
                        );
                    })}
                </ul>
            )}
        </Section>
    );
};

const query = graphql`
    query {
        image: file(relativePath: { eq: "section-portfolio.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
`;

export default SectionPortfolio;
