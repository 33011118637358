import React from 'react';
import { graphql } from 'gatsby';
import { IPage } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { slider, newsletterContent } from './index.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IJobOfferCard } from '../models/job-offer.model';
import { IPostCard } from '../models/post.model';
import { IClientOpinion } from '../models/client-opinion.model';
import { IStepsTimelinePoint } from '../models/steps-timeline-point.model';
import { IOfferCategory } from '../models/offer.model';
import { IProjectCard } from '../models/project';
import { IAboutCard } from '../models/about-card.model';
import { IBannerCollection } from '../models/banner-collection.model';
import { getNodes } from '../utils/get-nodes';
import { useT } from '../hooks/use-translation';

import MainLayout from '../layouts/main-layout';
import Banner from '../components/organisms/banner';
import Slider from '../components/hoc/slider';
import SectionAbout from '../components/organisms/section-about';
import SectionOffer from '../components/organisms/section-offer';
import SectionPortfolio from '../components/organisms/section-portfolio';
import SectionHowWeWork from '../components/organisms/section-how-we-work';
import SectionClients from '../components/organisms/section-clients';
import SectionBlog from '../components/organisms/section-blog';
import SectionCareer from '../components/organisms/section-career';
import SectionContact from '../components/organisms/section-contact';
import SectionNewsletter from '../components/organisms/section-newsletter';

interface IIndexPageProps {
    readonly data: {
        allJobOffer: IQueryAllResult<IJobOfferCard>;
        allPost: IQueryAllResult<IPostCard>;
        allPage: IQueryAllResult<Pick<IPage, 'type' | 'pathname'>>;
        allClientOpinion: IQueryAllResult<IClientOpinion>;
        allStepsTimelinePoint: IQueryAllResult<IStepsTimelinePoint>;
        allOfferCategory: IQueryAllResult<IOfferCategory>;
        allProject: IQueryAllResult<IProjectCard>;
        allAboutCard: IQueryAllResult<IAboutCard>;
        bannerCollection: IBannerCollection | null;
    };
}

const IndexPage: React.FC<IIndexPageProps> = ({ data }) => {
    const { t } = useT();
    const {
        allJobOffer,
        allPost,
        allPage,
        allClientOpinion,
        allStepsTimelinePoint,
        allOfferCategory,
        allProject,
        allAboutCard,
        bannerCollection,
    } = data;
    const jobOfferCards = getNodes(allJobOffer);
    const postCards = getNodes(allPost);
    const pages = getNodes(allPage);
    const clientOpinions = getNodes(allClientOpinion);
    const stepsTimelinePoint = getNodes(allStepsTimelinePoint);
    const offerCategories = getNodes(allOfferCategory);
    const projectCards = getNodes(allProject);
    const aboutCards = getNodes(allAboutCard);

    const blogPathname = pages.find((page) => page.type === 'blog')?.pathname || '';
    const careerPathname = pages.find((page) => page.type === 'career')?.pathname || '';
    const howPathname = pages.find((page) => page.type === 'how-do-we-work')?.pathname || '';
    const portfolioPathname = pages.find((page) => page.type === 'portfolio')?.pathname || '';

    return (
        <MainLayout>
            {bannerCollection && (
                <Slider className={slider} autoplay={{ delay: 10000 }} speed={500} rewind={true}>
                    {bannerCollection.banners.map((banner) => {
                        return <Banner key={`banner-${banner.bannerId}`} banner={banner} />;
                    })}
                </Slider>
            )}
            <SectionAbout
                aboutCards={aboutCards}
                titleProps={{
                    Tag: 'h1',
                    children: t('home.about.title'),
                    subtitle: t('home.about.subtitle'),
                    isSubtitleExcludedFromTag: true,
                }}
                textProps={{ children: t('home.about.text') }}
            />
            <SectionOffer
                titleProps={{
                    Tag: 'h2',
                    children: t('home.offer.title'),
                    subtitle: t('home.offer.subtitle'),
                }}
                textProps={{ Tag: 'p', children: t('home.offer.text') }}
                buttonProps={{
                    children: t('button.go'),
                    kind: 'normalDark',
                    color: 'yellow',
                }}
                offerCategories={offerCategories}
            />
            <SectionPortfolio
                titleProps={{
                    Tag: 'h2',
                    children: t('home.portfolio.title'),
                    subtitle: t('home.portfolio.subtitle'),
                }}
                textProps={{ Tag: 'p', children: t('home.portfolio.text') }}
                buttonProps={{
                    as: 'link',
                    to: portfolioPathname,
                    children: t('button.go'),
                    color: 'purple',
                }}
                projectCards={projectCards}
            />
            <SectionHowWeWork
                titleProps={{
                    Tag: 'h2',
                    children: t('home.how.title'),
                    subtitle: t('home.how.subtitle'),
                }}
                textProps={{ Tag: 'p', children: t('home.how.text') }}
                buttonProps={{
                    as: 'link',
                    to: howPathname,
                    children: t('button.go'),
                    kind: 'normalDark',
                    color: 'yellow',
                }}
                points={stepsTimelinePoint}
            />
            <SectionClients
                titleProps={{
                    Tag: 'h2',
                    children: t('home.clients.title'),
                    subtitle: t('home.clients.subtitle'),
                }}
                clientOpinions={clientOpinions}
            />
            <SectionBlog
                postCards={postCards}
                titleProps={{
                    Tag: 'h2',
                    children: t('home.blog.title'),
                    subtitle: t('home.blog.subtitle'),
                }}
                buttonProps={{
                    children: t('button.see.all'),
                    as: 'link',
                    to: blogPathname,
                }}
            />
            <SectionNewsletter
                theme="pink"
                titleProps={{
                    Tag: 'h2',
                    children: t('home.newsletter.title'),
                    subtitle: t('home.newsletter.subtitle'),
                }}
                buttonProps={{
                    color: 'light',
                }}
                inputTheme="semitransparent"
            >
                <h3 className={newsletterContent}>{t('home.newsletter.content')}</h3>
            </SectionNewsletter>
            <SectionCareer
                jobOfferCards={jobOfferCards}
                titleProps={{
                    Tag: 'h2',
                    children: t('home.career.title'),
                    subtitle: t('home.career.subtitle'),
                }}
                buttonProps={{
                    as: 'link',
                    children: t('button.see.all'),
                    to: careerPathname,
                }}
            />
            <SectionContact titleProps={{ Tag: 'h2', children: t('home.contact.title') }} />
        </MainLayout>
    );
};

export const query = graphql`
    query IndexPage($locale: String!) {
        bannerCollection(locale: { eq: $locale }) {
            ...bannerCollectionFields
        }
        allJobOffer(
            filter: { locale: { eq: $locale } }
            sort: { fields: [publishedAt, sequence], order: [DESC, ASC] }
            limit: 3
        ) {
            edges {
                node {
                    ...jobOfferCardFields
                }
            }
        }
        allPost(
            filter: { locale: { eq: $locale } }
            sort: { fields: [isPromoted, publishedAt], order: [DESC, DESC] }
            limit: 3
        ) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
        allClientOpinion(
            filter: { locale: { eq: $locale }, isGlobal: { eq: true } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...clientOpinionFields
                }
            }
        }
        allStepsTimelinePoint(
            filter: { locale: { eq: $locale } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...stepsTimelinePointFields
                }
            }
        }
        allOfferCategory(
            filter: { locale: { eq: $locale } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...offerCategoryFields
                }
            }
        }
        allProject(
            filter: { locale: { eq: $locale } }
            sort: { fields: sequence, order: ASC }
            limit: 3
        ) {
            edges {
                node {
                    ...projectCardFields
                }
            }
        }
        allAboutCard(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    ...aboutCardFields
                }
            }
        }
        allPage(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    type
                    pathname
                }
            }
        }
    }
`;

export default IndexPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
