import React, { useEffect, useState } from 'react';
import { motion, Variants } from 'framer-motion';

import {
    container,
    header,
    circle,
    svg,
    titleText,
    blue,
    pink,
    purple,
    yellow,
    textBox,
    button,
} from './about-card.module.scss';
import PencilIcon from '../../assets/images/svg/hand-with-pencil.svg';
import PenIcon from '../../assets/images/svg/pen.svg';
import ScissorsIcon from '../../assets/images/svg/scissors.svg';
import CirclesIcon from '../../assets/images/svg/circles.svg';
import { breakpoints } from '../../config/breakpoints';
import { IAboutCard, TAboutCardIcon } from '../../models/about-card.model';
import { TAccentColor } from '../../models/accent-color.model';
import useWindowWidth from '../../hooks/use-window-width';
import { useT } from '../../hooks/use-translation';

import Semicircle from '../atoms/semicircle';
import Text from '../atoms/text';
import Button from '../atoms/button';

interface IAboutCardProps {
    className?: string;
    TitleTag?: React.ElementType;
    aboutCard: IAboutCard;
}

const AboutCard: React.FC<IAboutCardProps> = ({ className = '', aboutCard, TitleTag = 'h2' }) => {
    const { t } = useT();
    const { color, icon, title, content } = aboutCard;

    const windowWidth = useWindowWidth();
    const isPhone = windowWidth && windowWidth < breakpoints.phone;
    const [isOpen, setIsOpen] = useState(!isPhone);

    const Icon = icons[icon];
    const colorClass = colorClasses[color];

    const handleToggleText = () => {
        setIsOpen((prev) => !prev);
    };

    useEffect(() => {
        setIsOpen(!isPhone);
    }, [isPhone]);

    return (
        <div className={`${container} ${className} ${colorClass}`}>
            <div className={header}>
                <Semicircle className={circle} color={color} orientation="right" />
                <Icon className={svg} />
            </div>
            <TitleTag className={titleText}>{title}</TitleTag>
            <Button className={button} onClick={handleToggleText} kind="text" color={color}>
                {t('button.see')}
            </Button>
            <motion.div
                className={textBox}
                initial={isOpen ? 'open' : 'closed'}
                animate={isOpen ? 'open' : 'closed'}
                variants={textVariants}
            >
                <Text>{content}</Text>
            </motion.div>
        </div>
    );
};

const textVariants: Variants = {
    closed: {
        height: 0,
        opacity: 0,
    },
    open: {
        height: 'auto',
        opacity: 1,
    },
};

const colorClasses: Record<TAccentColor, string> = {
    blue: blue,
    pink: pink,
    purple: purple,
    yellow: yellow,
};

const icons: Record<TAboutCardIcon, React.SVGFactory> = {
    pencil: PencilIcon,
    pen: PenIcon,
    circles: CirclesIcon,
    scissors: ScissorsIcon,
};

export default AboutCard;
