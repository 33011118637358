import React, { Suspense, useRef } from 'react';

import {
    top,
    mapBox,
    mapHeader,
    addressHeader,
    address,
    colored,
    linkBox,
    linkBoxHeader,
    button,
    floating,
    floating01,
    floating02,
    floating03,
    floating04,
    floating05,
    floating06,
    floating07,
    floating08,
    floating09,
    floating10,
    map,
} from './section-contact.module.scss';
import { grid } from '../../styles/grid.module.scss';
import { useT } from '../../hooks/use-translation';
import { googleMap } from '../../config/google-map';
import { useIsIntersecting } from '../../hooks/use-is-intersecting';

import Section from '../hoc/section';
import Text from '../atoms/text';
import Button from '../atoms/button';
import Title, { ITitleProps } from '../atoms/title';
import FloatingSemicircle, { IFloatingSemicircleProps } from '../atoms/floating-semicircle';

const Map = React.lazy(() => import('../molecules/map'));

interface ISectionContactProps {
    className?: string;
    titleProps?: ITitleProps;
}

const SectionContact: React.FC<ISectionContactProps> = ({ className = '', titleProps }) => {
    const { t } = useT();
    const mapBoxRef = useRef<HTMLDivElement | null>(null);
    const isMapInViewport = useIsIntersecting(mapBoxRef);

    return (
        <Section
            className={className}
            theme="dark"
            circleDisplay="none"
            contentDisplay="center"
            titleProps={titleProps}
            contentWidth="full"
        >
            <div className={`${top} ${grid}`}>
                <Text className={addressHeader} Tag="h3">
                    {t('address.header')}
                </Text>
                <address className={address}>
                    <a href={googleMap.link} target="_blank" rel="noreferrer noopener">
                        <Text Tag="div" className={colored}>
                            <p>{t('address.name')}</p>
                            <p>{t('address.street')}</p>
                            <p>{t('address.city')}</p>
                        </Text>
                    </a>
                    <Text Tag="div">
                        <p>{t('address.nip')}</p>
                        <p>{t('address.krs')}</p>
                        <p>{t('address.regon')}</p>
                    </Text>
                </address>
                <div className={linkBox}>
                    {floatingSemicircles.map((circle, index) => {
                        return (
                            <FloatingSemicircle key={`floating-semicircle-${index}`} {...circle} />
                        );
                    })}
                    <h3 className={linkBoxHeader}>
                        {t('home.contact.link.header.one')} <br />
                        {t('home.contact.link.header.two')}
                    </h3>
                    <Button
                        className={button}
                        color="light"
                        as="externalLink"
                        href={`mailto:${t('address.mail')}`}
                    >
                        {t('button.send.brief')}
                    </Button>
                </div>
                <Title className={mapHeader} size="small" Tag="h3">
                    {t('home.contact.map.header')}
                </Title>
            </div>
            <div className={mapBox} ref={mapBoxRef}>
                {isMapInViewport && (
                    <Suspense fallback={null}>
                        <Map className={map} />
                    </Suspense>
                )}
            </div>
        </Section>
    );
};

const floatingSemicircles: IFloatingSemicircleProps[] = [
    {
        className: `${floating} ${floating01}`,
        speed: 5000,
        rotateDeg: 65,
        color: 'blue',
    },
    {
        className: `${floating} ${floating02}`,
        speed: 12000,
        rotateDeg: 135,
        color: 'purple',
    },
    {
        className: `${floating} ${floating03}`,
        speed: 4000,
        rotateDeg: 160,
        color: 'blue',
    },
    {
        className: `${floating} ${floating04}`,
        speed: 9000,
        rotateDeg: -130,
        color: 'purple',
    },
    {
        className: `${floating} ${floating05}`,
        speed: 5000,
        rotateDeg: -60,
        color: 'pink',
    },
    {
        className: `${floating} ${floating06}`,
        speed: 5000,
        rotateDeg: -65,
        color: 'blue',
    },
    {
        className: `${floating} ${floating07}`,
        speed: 12000,
        rotateDeg: -135,
        color: 'purple',
    },
    {
        className: `${floating} ${floating08}`,
        speed: 4000,
        rotateDeg: -160,
        color: 'blue',
    },
    {
        className: `${floating} ${floating09}`,
        speed: 9000,
        rotateDeg: 130,
        color: 'purple',
    },
    {
        className: `${floating} ${floating10}`,
        speed: 5000,
        rotateDeg: 60,
        color: 'pink',
    },
];

export default SectionContact;
