import React from 'react';

import { list, card, item } from './section-blog.module.scss';
import { IPostCard } from '../../models/post.model';
import { ITitleProps } from '../atoms/title';
import { TButtonProps } from '../atoms/button';

import Section from '../hoc/section';
import PostCard from '../molecules/post-card';

interface ISectionBlogProps {
    className?: string;
    postCards: IPostCard[];
    titleProps?: ITitleProps;
    buttonProps?: TButtonProps;
}

const SectionBlog: React.FC<ISectionBlogProps> = ({
    className = '',
    postCards,
    titleProps,
    buttonProps,
}) => {
    return (
        <Section
            className={className}
            theme="dark"
            circleDisplay="right"
            circleColor="blue"
            contentDisplay="center"
            titleProps={titleProps}
            buttonProps={{ ...buttonProps, color: 'light' }}
        >
            <ul className={list}>
                {postCards.map((postCard) => {
                    return (
                        <li key={`post-card-${postCard.postId}`} className={item}>
                            <PostCard className={card} TitleTag="h3" postCard={postCard} />
                        </li>
                    );
                })}
            </ul>
        </Section>
    );
};

export default SectionBlog;
