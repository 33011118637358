// extracted by mini-css-extract-plugin
export var circle = "section-about-module--circle--AbEdp";
export var float = "section-about-module--float--CyArH";
export var floating = "section-about-module--floating--Fyg90";
export var floating01 = "section-about-module--floating-01--xf6-9";
export var floating02 = "section-about-module--floating-02--6NVLY";
export var floating03 = "section-about-module--floating-03--PfzM4";
export var floating04 = "section-about-module--floating-04--mpVwA";
export var floating05 = "section-about-module--floating-05--qD8zv";
export var floating06 = "section-about-module--floating-06--1ZJkK";
export var list = "section-about-module--list--rEfSv";
export var section = "section-about-module--section--8UF9X";
export var top = "section-about-module--top--9gAlD";