import React, { useEffect, useRef, useState } from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    container,
    parallax,
    imageBox,
    ratio,
    content,
    typewriter,
    desktop,
    mobile,
} from './banner.module.scss';
import { relations } from '../../config/relations';
import { IBanner } from '../../models/banner.model';
import { ISlideData } from '../hoc/slider';

import Typewriter, { ITTypewriterProps } from '../atoms/typewriter';
import Button from '../atoms/button';

interface IBannerProps {
    className?: string;
    banner: IBanner;
    slideData?: ISlideData;
    typewriterProps?: Omit<ITTypewriterProps, 'children' | 'className'>;
}

interface ICoordinates {
    x: number;
    y: number;
}

const Banner: React.FC<IBannerProps> = ({ className = '', banner, typewriterProps, slideData }) => {
    const { media, title, buttonUrl, buttonText } = banner;

    const mousePositionRef = useRef<null | ICoordinates>(null);
    const [offset, setOffset] = useState<ICoordinates>({ x: 0, y: 0 });
    const [currentInterval, setCurrentInterval] = useState<NodeJS.Timer | null>(null);

    const handleOnMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
        if (mousePositionRef.current === null) {
            mousePositionRef.current = { x: event.clientX, y: event.clientY };
            return;
        }

        const prevMousePosition = mousePositionRef.current;
        const newMousePosition = { x: event.clientX, y: event.clientY };
        mousePositionRef.current = newMousePosition;

        const delta: ICoordinates = {
            x: (prevMousePosition.x - newMousePosition.x) / 80,
            y: (prevMousePosition.y - newMousePosition.y) / 80,
        };

        setOffset((prevOffset) => {
            return {
                x: prevOffset.x + delta.x,
                y: prevOffset.y - delta.y,
            };
        });
    };

    const handleOnMouseLeave = () => {
        mousePositionRef.current = null;
        const resetOffset = () => {
            setOffset((prevOffset) => {
                const newOffset = getOffsetCloserToInitial(prevOffset);
                if (newOffset.x === 0 && newOffset.y === 0) {
                    clearInterval(interval);
                }
                return newOffset;
            });
        };
        const interval = setInterval(resetOffset, 30);
        setCurrentInterval(interval);
    };

    const handleMouseEnter = () => {
        if (currentInterval) {
            clearInterval(currentInterval);
        }
    };

    useEffect(() => {
        return () => {
            if (currentInterval) {
                clearInterval(currentInterval);
            }
        };
    }, [currentInterval]);

    return (
        <div
            className={`${container} ${className}`}
            onMouseMove={handleOnMouseOver}
            onMouseLeave={handleOnMouseLeave}
            onMouseEnter={handleMouseEnter}
        >
            <div
                className={parallax}
                style={{
                    transform: `translate(calc(-50% + ${offset.x}px), calc(-50% + ${offset.y}px))`,
                }}
            >
                <Image media={media} className={`${imageBox} ${desktop}`} ratioClass={ratio} />
                <Image
                    media={media}
                    relation={relations.mainImageMobile}
                    className={`${imageBox} ${mobile}`}
                    ratioClass={ratio}
                />
            </div>
            <div className={content}>
                {title && (
                    <Typewriter
                        className={typewriter}
                        canType={checkIfCanType(slideData, typewriterProps)}
                        resetOnCanTypeChange={checkIfCanReset(slideData, typewriterProps)}
                    >
                        {title}
                    </Typewriter>
                )}
                {buttonUrl && buttonText && (
                    <Button as="link" to={buttonUrl} color="purple" isHoverTransparent={false}>
                        {buttonText}
                    </Button>
                )}
            </div>
        </div>
    );
};

function checkIfCanType(
    slideData: IBannerProps['slideData'],
    typewriterProps: IBannerProps['typewriterProps']
): boolean {
    if (!slideData && !typewriterProps) return true;
    if (typewriterProps?.canType !== undefined) return typewriterProps.canType;
    if (slideData) return slideData.isActive;
    return true;
}

function checkIfCanReset(
    slideData: IBannerProps['slideData'],
    typewriterProps: IBannerProps['typewriterProps']
): boolean {
    if (!slideData && !typewriterProps) return false;
    if (typewriterProps?.resetOnCanTypeChange !== undefined) {
        return typewriterProps.resetOnCanTypeChange;
    }
    return !!slideData;
}

function getOffsetCloserToInitial(prevOffset: ICoordinates): ICoordinates {
    return {
        x: getNumberCloserToZero(prevOffset.x),
        y: getNumberCloserToZero(prevOffset.y),
    };
}

function getNumberCloserToZero(number: number): number {
    if (number < -1) {
        return number + 1;
    }
    if (number > 1) {
        return number - 1;
    }
    return 0;
}

export default Banner;
