import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import type { Swiper } from 'swiper';

import {
    sliderBox,
    slideTitle,
    sliderNav,
    navButton,
    navButtonText,
    active,
    slider,
} from './section-offer.module.scss';
import { IOfferCategory } from '../../models/offer.model';
import { ITitleProps } from '../atoms/title';
import { TButtonProps } from '../atoms/button';

import Section from '../hoc/section';
import Text, { ITextProps } from '../atoms/text';
import Slider from '../hoc/slider';

interface ISectionOfferProps {
    className?: string;
    titleProps: ITitleProps;
    textProps: ITextProps;
    buttonProps: Pick<
        TButtonProps,
        'children' | 'color' | 'kind' | 'hasArrow' | 'isHoverTransparent' | 'justify'
    >;
    offerCategories: IOfferCategory[];
}

interface ISectionOfferQueryResult {
    image: ImageDataLike;
}

const SectionOffer: React.FC<ISectionOfferProps> = ({
    className = '',
    offerCategories,
    buttonProps,
    titleProps,
    textProps,
}) => {
    const { image }: ISectionOfferQueryResult = useStaticQuery(query);
    const [swiperSlider, setSwiperSlider] = useState<null | Swiper>(null);
    const [activeIndex, setActiveIndex] = useState<null | number>(null);

    const activeCategory = offerCategories[activeIndex || 0];

    const handleSliderInit = (swiper: Swiper) => {
        setSwiperSlider(swiper);
        setActiveIndex(swiper.activeIndex);
    };

    const handleNavChange = (slideIndex: number) => {
        return () => {
            if (!swiperSlider) return;
            swiperSlider.slideTo(slideIndex);
        };
    };

    const handleSlideChange = (swiper: Swiper) => {
        setActiveIndex(swiper.activeIndex);
    };

    return (
        <Section
            className={className}
            theme="dark"
            circleColor="pink"
            circleDisplay="left"
            contentDisplay="right"
            titleProps={titleProps}
            textProps={textProps}
            buttonProps={{
                ...buttonProps,
                to: activeCategory?.pathname || '',
                as: 'link',
            }}
            image={image && getImage(image)}
        >
            <div className={sliderBox}>
                <div className={sliderNav}>
                    {offerCategories.map((offerCategory, index) => {
                        const activeClass =
                            activeIndex !== null && activeIndex === index ? active : '';
                        return (
                            <button
                                key={`slide-nav-button-${offerCategory.offerCategoryId}`}
                                className={`${navButton} ${activeClass}`}
                                onClick={handleNavChange(index)}
                            >
                                <span className={navButtonText}>{index + 1}</span>
                            </button>
                        );
                    })}
                </div>
                <div className={slider}>
                    <Slider
                        autoplay={false}
                        autoHeight={true}
                        onInit={handleSliderInit}
                        onSlideChange={handleSlideChange}
                        spaceBetween={20}
                        rewind={true}
                    >
                        {offerCategories.map((offerCategory) => {
                            return (
                                <div key={`offer-slide-${offerCategory.offerCategoryId}`}>
                                    <Text Tag="h3" className={slideTitle}>
                                        {offerCategory.name}
                                    </Text>
                                    <Text>{offerCategory.description}</Text>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </Section>
    );
};

const query = graphql`
    query {
        image: file(relativePath: { eq: "section-offer.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
`;

export default SectionOffer;
