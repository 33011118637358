import React, { useEffect, useRef, useState } from 'react';

import { container, blinker } from './typewriter.module.scss';

export interface ITTypewriterProps {
    className?: string;
    Tag?: React.ElementType;
    children?: string;
    speed?: number;
    canType?: boolean;
    resetOnCanTypeChange?: boolean;
}

const Typewriter: React.FC<ITTypewriterProps> = ({
    className = '',
    Tag = 'p',
    speed = 100,
    canType = true,
    resetOnCanTypeChange = false,
    children = '',
}) => {
    const [text, setText] = useState('');
    const positionRef = useRef(0);

    useEffect(() => {
        if (!children || !canType) return;
        const typeText = () => {
            if (positionRef.current - 1 === children.length) {
                clearInterval(interval);
                return;
            }
            const newText = children.substring(0, positionRef.current);
            setText(newText);
            positionRef.current = positionRef.current + 1;
        };
        const interval = setInterval(typeText, speed);
        return () => {
            clearInterval(interval);
            positionRef.current = 0;
            setText('');
        };
    }, [children, speed, canType]);

    useEffect(() => {
        setText('');
    }, [resetOnCanTypeChange]);

    if (!children) return null;

    return (
        <Tag className={`${container} ${className}`}>
            {text}
            <span className={blinker}>|</span>
        </Tag>
    );
};

export default Typewriter;
