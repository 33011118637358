// extracted by mini-css-extract-plugin
export var address = "section-contact-module--address--ZA3it";
export var addressHeader = "section-contact-module--address-header--8vKzg";
export var button = "section-contact-module--button--m3EtN";
export var colored = "section-contact-module--colored--MZoPW";
export var floating = "section-contact-module--floating--KmIgS";
export var floating01 = "section-contact-module--floating-01--fBYWN";
export var floating02 = "section-contact-module--floating-02--QS1OR";
export var floating03 = "section-contact-module--floating-03--RnqKn";
export var floating04 = "section-contact-module--floating-04--MtQTX";
export var floating05 = "section-contact-module--floating-05--8qZ1l";
export var floating06 = "section-contact-module--floating-06--JwRpP";
export var floating07 = "section-contact-module--floating-07--jv7Sf";
export var floating08 = "section-contact-module--floating-08--y1f1U";
export var floating09 = "section-contact-module--floating-09--VWqvZ";
export var floating10 = "section-contact-module--floating-10--p+u2M";
export var linkBox = "section-contact-module--link-box--2gGz+";
export var linkBoxHeader = "section-contact-module--link-box-header--5qTGr";
export var map = "section-contact-module--map--I5PQG";
export var mapBox = "section-contact-module--map-box--G2MqI";
export var mapHeader = "section-contact-module--map-header--E3fo5";
export var top = "section-contact-module--top--gQpq8";