import React from 'react';

import { list, item, card } from './section-career.module.scss';
import { IJobOfferCard } from '../../models/job-offer.model';
import { ITitleProps } from '../atoms/title';
import { TButtonProps } from '../atoms/button';

import Section from '../hoc/section';
import JobOfferCard from '../molecules/job-offer-card';

interface ISectionCareerProps {
    className?: string;
    jobOfferCards: IJobOfferCard[];
    titleProps?: ITitleProps;
    buttonProps?: TButtonProps;
}

const SectionCareer: React.FC<ISectionCareerProps> = ({
    className = '',
    jobOfferCards,
    titleProps,
    buttonProps,
}) => {
    return (
        <Section
            className={className}
            theme="light"
            contentDisplay="center"
            circleDisplay="none"
            titleProps={titleProps}
            buttonProps={{ ...buttonProps, color: 'dark' }}
        >
            <ul className={list}>
                {jobOfferCards.map((jobOfferCard) => {
                    return (
                        <li key={`job-offer-item-${jobOfferCard.jobOfferId}`} className={item}>
                            <JobOfferCard className={card} jobOfferCard={jobOfferCard} />
                        </li>
                    );
                })}
            </ul>
        </Section>
    );
};

export default SectionCareer;
