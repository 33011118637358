// extracted by mini-css-extract-plugin
export var blue = "client-opinion-card-module--blue--VybYA";
export var circle = "client-opinion-card-module--circle--k5Dpy";
export var container = "client-opinion-card-module--container--69w9b";
export var imgBox = "client-opinion-card-module--img-box--EwIEs";
export var line = "client-opinion-card-module--line--T4kUn";
export var pink = "client-opinion-card-module--pink--Nwwmc";
export var purple = "client-opinion-card-module--purple--AqW30";
export var ratio = "client-opinion-card-module--ratio--bOYo+";
export var separator = "client-opinion-card-module--separator--DNBqE";
export var signature = "client-opinion-card-module--signature--3hG6b";
export var yellow = "client-opinion-card-module--yellow--KlhsD";