import React from 'react';

import {
    section,
    circle,
    list,
    top,
    floating,
    floating01,
    floating02,
    floating03,
    floating04,
    floating05,
    floating06,
} from './section-about.module.scss';
import { IAboutCard } from '../../models/about-card.model';

import Title, { ITitleProps } from '../atoms/title';
import Semicircle from '../atoms/semicircle';
import Text, { ITextProps } from '../atoms/text';
import FloatingSemicircle, { IFloatingSemicircleProps } from '../atoms/floating-semicircle';
import AboutCard from '../molecules/about-card';
import Section from '../hoc/section';

interface ISectionAboutProps {
    className?: string;
    aboutCards: IAboutCard[];
    titleProps?: ITitleProps;
    textProps?: ITextProps;
}

const SectionAbout: React.FC<ISectionAboutProps> = ({
    className = '',
    aboutCards,
    titleProps,
    textProps,
}) => {
    return (
        <Section circleDisplay="none" theme="light" className={`${section} ${className}`}>
            <div className={top}>
                <Title {...titleProps} />
                <Semicircle color="random" orientation="bottom" className={circle} />
                <Text {...textProps} />
                {floatingSemicircles.map((circle, index) => {
                    return <FloatingSemicircle key={`floating-semicircle-${index}`} {...circle} />;
                })}
            </div>
            {aboutCards.length > 0 && (
                <ul className={list}>
                    {aboutCards.map((aboutCard) => {
                        return (
                            <li key={`about-item-${aboutCard.aboutCardId}`}>
                                <AboutCard aboutCard={aboutCard} TitleTag="h2" />
                            </li>
                        );
                    })}
                </ul>
            )}
        </Section>
    );
};

const floatingSemicircles: IFloatingSemicircleProps[] = [
    {
        className: `${floating} ${floating01}`,
        speed: 5000,
        rotateDeg: -35,
        color: 'blue',
    },
    {
        className: `${floating} ${floating02}`,
        speed: 7000,
        rotateDeg: 55,
        color: 'yellow',
    },
    {
        className: `${floating} ${floating03}`,
        speed: 4000,
        rotateDeg: 95,
        color: 'pink',
    },
    {
        className: `${floating} ${floating04}`,
        speed: 4000,
        rotateDeg: -85,
        color: 'pink',
    },
    {
        className: `${floating} ${floating05}`,
        speed: 5000,
        rotateDeg: -120,
        color: 'blue',
    },
    {
        className: `${floating} ${floating06}`,
        speed: 6000,
        rotateDeg: 150,
        color: 'yellow',
    },
];

export default SectionAbout;
